import styled from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav/Nav";
import About from "./components/Pages/About";
import Contact from "./components/Pages/Contact";
import Home from "./components/Pages/Home";

const Container = styled.div`
  background-color: black;
  color: white;
  height: 100vh;
  padding: 50px;
  font-family: "Alumni Sans Pinstripe", sans-serif;
`;
const Footer = styled.div`
  bottom: 10px;
  position: fixed;
  right: 30px;
`;

function App() {
  return (
    <Container className="App" data-testid="app">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" exact element={<Home />}></Route>
        </Routes>
      </BrowserRouter>
      <Footer>Designed by @creative click multimedia 2022</Footer>
    </Container>
  );
}

export default App;
