import { Link } from "react-router-dom";
import styled from "styled-components";
import Links from "./Links";
import LogoIcon from "../../logo.png";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.9);
  height: 50px;
`;

const Logo = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: white;
`;
const Image = styled.img`
  height: 8vmin;
`;

const Nav = () => {
  return (
    <Container data-testid="nav">
      <Logo to="/">
        <Image src={LogoIcon} alt="creative click multimedia" />
      </Logo>
      <Links />
    </Container>
  );
};
export default Nav;
