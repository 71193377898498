import { Link } from "react-router-dom";
import styled from "styled-components";
import BackgroundImage from "../../../background.jpg";
import { COLORS } from "../../utils/colors";

const Container = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  background-image: url(${BackgroundImage});
  background-size: cover;
  height: fit-content;
`;

const SecondaryContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
  z-index; 2;
  padding: 20px;
`;
const ExtraDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

const Title = styled.h1`
  font-size: 2.5em;
`;
const SubTitle = styled.h2`
  // font-size: 1.8em;
`;
const Body = styled.div`
  line-height: 25px;
  font-size: 18px;
  letter-spacing: 2px;
  height: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactButton = styled(Link)`
  text-decoration: none;
  display: block;
  padding: 5px;
  margin-top: 15px;
  background: ${COLORS.secondary};
  color: white;
  width: 100px;
  text-align: center;
  &:hover {
    background: ${COLORS.primary};
  }
`;

const About = () => {
  return (
    <Container>
      <ExtraDiv>
        <SecondaryContainer>
          <SubTitle>Want to know more about us?</SubTitle>
          <Title>Creative Click</Title>
          <Body>
            <div>
              Is a software development firm which specialises in building
              mobile applications and web application for companies. We pride
              ourselves on simplicity and providing cutting-edge solution that
              helps companies be more efficient and productive.
            </div>
            <ContactButton to="/contact">Contact us</ContactButton>
          </Body>
        </SecondaryContainer>
      </ExtraDiv>
    </Container>
  );
};

export default About;
