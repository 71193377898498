import styled from "styled-components";
import BackgroundImage from "../../../background.jpg";
import Card from "../../Card";
import { COLORS } from "../../utils/colors";

const Container = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  background-image: url(${BackgroundImage});
  background-size: cover;
  height: fit-content;
`;

const SecondaryContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
  z-index; 2;
  padding: 20px;
`;
const ExtraDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

const Title = styled.h1`
  font-size: 2em;
  color: white;
`;
const SubTitle = styled.h2`
  font-size: 1.8em;
  color: white;
`;
const Title2 = styled.div`
  font-size: 1.1em;
  color: white;
`;

const CustomCard = styled(Card)`
  background: ${COLORS.secondary};
`;
const CustomLink = styled.a`
  text-decoration: none;
  color: white;
`;

const Contact = () => {
  return (
    <Container>
      <ExtraDiv>
        <SecondaryContainer>
          <SubTitle>Want to reach us?</SubTitle>
          <CustomCard
            title={<Title>Contact us</Title>}
            content={
              <Title2>
                via{" "}
                <CustomLink href="mailto:creativeclickmultimedia@gmail.com">
                  creativeclickmultimedia@gmail.com
                </CustomLink>{" "}
                for more information
              </Title2>
            }
          />
        </SecondaryContainer>
      </ExtraDiv>
    </Container>
  );
};

export default Contact;
