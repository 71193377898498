import styled from "styled-components";
import { COLORS } from "../utils/colors";

const Container = styled.div`
  height: 400px;
  width: 250px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 2px 16px;
  border-radius: 5px;
  cursor: pointer;
  background: ${COLORS.primary};
  &:hover {
    background: ${COLORS.primary};
  }
`;

const Title = styled.div`
  color: black;
  font-size: 30px;
  font-weight: bold;
`;
const Body = styled.div`
  margin-top: 50px;
  font-size: 20px;
  bottom: 0px;
  color: black;
`;

const Card = ({ title, content }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Body>{content}</Body>
    </Container>
  );
};
export default Card;
