import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../utils/colors";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
`;
const Item = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 25px;
  &:hover {
    color: ${COLORS.secondary};
  }
  &.active {
    color: ${COLORS.secondary};
  }
`;

const Links = () => {
  const { pathname } = useLocation();
  console.log(pathname);
  return (
    <Container data-testid="Links">
      <Item
        data-testid="home-Item"
        to="/"
        className={pathname === "/" && "active"}
      >
        Home
      </Item>
      <Item
        data-testid="about-Item"
        to="/about"
        className={pathname === "/about" && "active"}
      >
        About
      </Item>
      <Item
        data-testid="contact-Item"
        to="/contact"
        className={pathname === "/contact" && "active"}
      >
        Contact
      </Item>
    </Container>
  );
};

export default Links;
